@charset "UTF-8";
/* foundation */
/* mixin */
/****************************************************************/
/* editor / CMS 用 */
/****************************************************************/
/****************************************************************/
/* clearfix */
/****************************************************************/
/****************************************************************/
/* letter-spacing */
/****************************************************************/
/****************************************************************/
/* icon font */
/****************************************************************/
/****************************************************************/
/* font family */
/****************************************************************/
/****************************************************************/
/* コンテンツ幅を保って背景全画面 */
/****************************************************************/
/****************************************************************/
/* コンテンツ幅を保って左寄せ */
/****************************************************************/
/****************************************************************/
/* コンテンツ幅を保って右寄せ */
/****************************************************************/
/****************************************************************/
/* コンテンツ幅 */
/****************************************************************/
/****************************************************************/
/* 画面幅いっぱいに表示 */
/****************************************************************/
/****************************************************************/
/* hover effect */
/****************************************************************/
/****************************************************************/
/* margin-responsive */
/****************************************************************/
/****************************************************************/
/* padding-responsive */
/****************************************************************/
/****************************************************************/
/* theme-color */
/****************************************************************/
/****************************************************************/
/* font-size */
/****************************************************************/
/* project */
/* media-parts */
:where(.p-media-parts + .p-media-parts) {
  margin-top: 50px; }
  @media screen and (max-width: 767.8px) {
    :where(.p-media-parts + .p-media-parts) {
      margin-top: 35px; } }
  @media screen and (max-width: 575.8px) {
    :where(.p-media-parts + .p-media-parts) {
      margin-top: 25px; } }

.p-media-parts--margin-lg {
  margin-top: 80px; }
  @media screen and (max-width: 767.8px) {
    .p-media-parts--margin-lg {
      margin-top: 56px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts--margin-lg {
      margin-top: 40px; } }

.p-media-parts a {
  transition: all 0.2s ease-in;
  text-decoration: none; }

.p-media-parts__img {
  width: 100%; }
  .p-media-parts__img img {
    width: 100%; }

.p-media-parts__txt {
  font-weight: 500; }

.p-media-parts__toc {
  background-color: #f8f9fa;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px; }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__toc {
      padding-top: 21px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__toc {
      padding-top: 15px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__toc {
      padding-bottom: 21px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__toc {
      padding-bottom: 15px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__toc {
      padding-left: 21px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__toc {
      padding-left: 15px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__toc {
      padding-right: 21px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__toc {
      padding-right: 15px; } }
  .p-media-parts__toc-inside {
    border-left: 5px solid #1796E5;
    padding-left: 20px; }
  .p-media-parts__toc-head {
    font-size: calc(calc($size / 16) * 1rem);
    font-weight: bold; }
  .p-media-parts__toc-list {
    margin-top: 15px;
    padding-left: 25px; }
    .p-media-parts__toc-list__item + .p-media-parts__toc-list__item {
      margin-top: 10px; }
      @media screen and (max-width: 767.8px) {
        .p-media-parts__toc-list__item + .p-media-parts__toc-list__item {
          margin-top: 7px; } }
      @media screen and (max-width: 575.8px) {
        .p-media-parts__toc-list__item + .p-media-parts__toc-list__item {
          margin-top: 5px; } }
    .p-media-parts__toc-list__item::marker {
      font-weight: 700; }
    .p-media-parts__toc-list__item a {
      color: inherit; }
      .p-media-parts__toc-list__item a:hover {
        opacity: 0.7; }

.p-media-parts__h2 {
  font-size: calc(calc($size / 16) * 1rem);
  font-weight: bold;
  position: relative;
  border-top: 4px solid #6c757d;
  border-bottom: 1px solid #dddddd;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: content-box; }
  .p-media-parts__h2::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 4px;
    background-color: #1796E5;
    left: 0;
    top: -4px; }

.p-media-parts__h3 {
  font-size: calc(calc($size / 16) * 1rem);
  font-weight: bold;
  color: #1796E5; }
  .p-media-parts__h3--black {
    color: #333; }

.p-media-parts__h4 {
  font-size: calc(calc($size / 16) * 1rem);
  font-weight: bold;
  padding-left: 20px;
  position: relative; }
  .p-media-parts__h4::before {
    content: "";
    height: calc(100% - 5px);
    width: 3px;
    background-color: #1796E5;
    position: absolute;
    top: 0;
    left: 0; }
  .p-media-parts__h4::after {
    content: "";
    height: calc(100% - 5px);
    width: 3px;
    background-color: #e9ecef;
    position: absolute;
    bottom: 0;
    left: 3px; }

.p-media-parts__h5 {
  font-size: calc(calc($size / 16) * 1rem);
  color: #1796E5;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #cccccc; }

.p-media-parts__box {
  display: flex; }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__box {
      flex-flow: column-reverse; } }
  .p-media-parts__box--reverse {
    flex-flow: row-reverse; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__box--reverse {
        flex-flow: column-reverse; } }
  .p-media-parts__box--reverse .p-media-parts__box-str {
    margin-right: 0;
    margin-left: 30px; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__box--reverse .p-media-parts__box-str {
        margin-left: 0; } }
  .p-media-parts__box-str {
    width: 60%;
    margin-right: 30px; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__box-str {
        width: 100%;
        margin-right: 0;
        margin-top: 10px; } }
  .p-media-parts__box-img {
    flex: 1; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__box-img {
        width: 100%; } }
    .p-media-parts__box-img img {
      width: 100%; }
  .p-media-parts__box-txt {
    margin-top: 15px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__box-txt {
        margin-top: 10.5px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__box-txt {
        margin-top: 7.5px; } }
  .p-media-parts__box-link {
    display: block;
    margin-top: 25px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__box-link {
        margin-top: 17.5px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__box-link {
        margin-top: 12.5px; } }
    .p-media-parts__box-link:hover {
      text-decoration: none;
      opacity: 0.7; }
    .p-media-parts__box-link::before {
      content: "＞";
      margin-right: 5px; }

.p-media-parts__quote {
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: rgba(23, 150, 229, 0.1);
  width: 100%; }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__quote {
      padding-bottom: 28px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__quote {
      padding-bottom: 20px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__quote {
      padding-left: 28px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__quote {
      padding-left: 20px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__quote {
      padding-right: 28px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__quote {
      padding-right: 20px; } }
  .p-media-parts__quote-icon {
    width: 38px;
    height: auto;
    display: block;
    margin-left: -20px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__quote-icon {
        margin-left: -14px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__quote-icon {
        margin-left: -10px; } }
  .p-media-parts__quote * {
    font-size: calc(calc($size / 16) * 1rem);
    margin-top: 10px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__quote * {
        margin-top: 7px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__quote * {
        margin-top: 5px; } }

.p-media-parts__source {
  margin-bottom: 0; }
  .p-media-parts__source a {
    color: #1796E5;
    text-decoration: underline; }
    .p-media-parts__source a:hover {
      opacity: 0.7; }

.p-media-parts__list {
  padding-left: 20px;
  list-style-type: disc; }
  .p-media-parts__list-item + .p-media-parts__list-item {
    margin-top: 8px; }
  .p-media-parts__list--num {
    list-style-type: decimal; }

.p-media-parts__table {
  width: 100%; }
  .p-media-parts__table th,
  .p-media-parts__table td {
    padding: 10px; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__table th,
      .p-media-parts__table td {
        width: 100%;
        display: block; } }
  .p-media-parts__table th {
    background-color: #1796E5;
    color: #ffffff;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    font-weight: 700; }
  .p-media-parts__table tr:nth-child(odd) td {
    background-color: #f8f9fa; }
  .p-media-parts__table td {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; }
  .p-media-parts__table--multi {
    width: auto;
    min-width: 100%;
    white-space: nowrap; }
    .p-media-parts__table--multi th,
    .p-media-parts__table--multi td {
      display: table-cell; }
    .p-media-parts__table--multi th + th {
      border-left: 1px solid #ffffff; }

.p-media-parts__scroll {
  overflow-x: auto; }

.p-media-parts__btn {
  width: 100%;
  max-width: 360px;
  border-radius: 50vh;
  background: #1796E5;
  border: 1px solid #1796E5;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .p-media-parts__btn:hover {
    background: #ffffff;
    color: #1796E5;
    text-decoration: none; }
  .p-media-parts__btn--left {
    margin-left: 0; }
  .p-media-parts__btn--right {
    margin-right: 0; }
  .p-media-parts__btn + .p-media-parts__btn {
    margin-top: 15px; }

.p-media-parts__video {
  position: relative;
  padding-top: calc(9 / 16 * 100%);
  height: 0; }
  .p-media-parts__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .p-media-parts__videos {
    display: flex; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__videos {
        flex-flow: column; } }
  .p-media-parts__video-wrap {
    flex: 1; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__video-wrap {
        width: 100%; } }
    .p-media-parts__video-wrap + .p-media-parts__video-wrap {
      margin-left: 25px; }
      @media screen and (max-width: 575.8px) {
        .p-media-parts__video-wrap + .p-media-parts__video-wrap {
          margin-left: 0;
          margin-top: 15px; } }

.p-media-parts__item {
  width: 100%; }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__item + .p-media-parts__item {
      margin-top: 15px; } }
  .p-media-parts__item img {
    width: 100%; }
  .p-media-parts__item-txt {
    margin-top: 15px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__item-txt {
        margin-top: 10.5px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__item-txt {
        margin-top: 7.5px; } }
  .p-media-parts__items {
    display: flex;
    margin-left: -25px; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__items {
        flex-flow: column;
        margin-left: 0; } }
  .p-media-parts__items .p-media-parts__item {
    flex: 1;
    margin-left: 25px; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__items .p-media-parts__item {
        margin-left: 0;
        width: 100%; } }

.p-media-parts__card {
  display: flex;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: #f8f9fa; }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__card {
      padding-top: 31.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__card {
      padding-top: 22.5px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__card {
      padding-bottom: 31.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__card {
      padding-bottom: 22.5px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__card {
      padding-left: 31.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__card {
      padding-left: 22.5px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__card {
      padding-right: 31.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__card {
      padding-right: 22.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__card {
      flex-flow: column-reverse; } }
  .p-media-parts__card-txt {
    margin-top: 15px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__card-txt {
        margin-top: 10.5px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__card-txt {
        margin-top: 7.5px; } }
  .p-media-parts__card-str {
    width: 55%; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__card-str {
        width: 100%;
        margin-top: 10px; } }
  .p-media-parts__card-img {
    margin-left: 40px;
    flex: 1; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__card-img {
        width: 100%;
        margin-left: 0; } }
    .p-media-parts__card-img img {
      width: 100%;
      height: auto; }

.p-media-parts__cv {
  background-color: #f8f9fa;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex; }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__cv {
      padding-top: 17.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__cv {
      padding-top: 12.5px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__cv {
      padding-bottom: 17.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__cv {
      padding-bottom: 12.5px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__cv {
      padding-left: 17.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__cv {
      padding-left: 12.5px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__cv {
      padding-right: 17.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__cv {
      padding-right: 12.5px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__cv {
      flex-flow: column; } }
  .p-media-parts__cv-img {
    width: 150px; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__cv-img {
        margin-left: auto;
        margin-right: auto; } }
    .p-media-parts__cv-img img {
      width: 100%;
      height: auto; }
  .p-media-parts__cv-str {
    margin-left: 40px;
    flex: 1; }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__cv-str {
        width: 100%;
        margin-left: 0;
        margin-top: 10px; } }
  .p-media-parts__cv-head {
    font-size: calc(calc($size / 16) * 1rem);
    font-weight: 700; }
  .p-media-parts__cv-txt {
    margin-top: 15px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__cv-txt {
        margin-top: 10.5px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__cv-txt {
        margin-top: 7.5px; } }
  .p-media-parts__cv-btn {
    margin-top: 15px; }
    @media screen and (max-width: 767.8px) {
      .p-media-parts__cv-btn {
        margin-top: 10.5px; } }
    @media screen and (max-width: 575.8px) {
      .p-media-parts__cv-btn {
        margin-top: 7.5px; } }

.p-media-parts__company {
  border: 8px solid #f8f9fa;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  align-items: center; }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__company {
      padding-top: 14px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__company {
      padding-top: 10px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__company {
      padding-bottom: 14px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__company {
      padding-bottom: 10px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__company {
      padding-left: 14px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__company {
      padding-left: 10px; } }
  @media screen and (max-width: 767.8px) {
    .p-media-parts__company {
      padding-right: 14px; } }
  @media screen and (max-width: 575.8px) {
    .p-media-parts__company {
      padding-right: 10px; } }
  .p-media-parts__company-name {
    font-size: calc(calc($size / 16) * 1rem);
    font-weight: 700;
    margin-bottom: 0; }
  .p-media-parts__company-link {
    font-size: calc(calc($size / 16) * 1rem);
    display: block;
    margin-top: 10px; }
    .p-media-parts__company-link:hover {
      opacity: 0.7; }
  .p-media-parts__company-table {
    margin-top: 20px; }

.p-media-parts__list {
  list-style: disc; }
  .p-media-parts__list-item {
    margin-top: 8px; }
