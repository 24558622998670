.p-media-parts {
  :where(& + &) {
    @include margin-responsive(50px);
  }

  &--margin-lg {
    @include margin-responsive(80px);
  }

  a {
    transition: $transition;
    text-decoration: none;
  }

  &__img {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__txt {
    font-weight: $medium;
  }

  &__toc {
    background-color: theme-color("gray-100");
    @include padding-top-responsive(30px);
    @include padding-bottom-responsive(30px);
    @include padding-left-responsive(30px);
    @include padding-right-responsive(30px);

    &-inside {
      border-left: 5px solid $main;
      padding-left: 20px;
    }

    &-head {
      font-size: fz(20);
      font-weight: bold;
    }

    &-list {
      margin-top: 15px;
      padding-left: 25px;

      &__item {
        & + & {
          @include margin-responsive(10px);
        }

        &::marker {
          font-weight: $bold;
        }

        a {
          color: inherit;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  &__h2 {
    font-size: fz(28);
    font-weight: bold;
    position: relative;
    border-top: 4px solid theme-color("gray-600");
    border-bottom: 1px solid #dddddd;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: content-box;

    &::before {
      content: "";
      position: absolute;
      width: 60px;
      height: 4px;
      background-color: $main;
      left: 0;
      top: -4px;
    }
  }

  &__h3 {
    font-size: fz(24);
    font-weight: bold;
    color: $main;

    &--black {
      color: $color-body;
    }
  }

  &__h4 {
    font-size: fz(20);
    font-weight: bold;
    padding-left: 20px;
    position: relative;

    &::before {
      content: "";
      height: calc(100% - 5px);
      width: 3px;
      background-color: $main;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: "";
      height: calc(100% - 5px);
      width: 3px;
      background-color: #e9ecef;
      position: absolute;
      bottom: 0;
      left: 3px;
    }
  }

  &__h5 {
    font-size: fz(20);
    color: $main;
    font-weight: $bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #cccccc;
  }

  &__box {
    display: flex;
    @include mq(max, sm) {
      flex-flow: column-reverse;
    }

    &--reverse {
      flex-flow: row-reverse;
      @include mq(max, sm) {
        flex-flow: column-reverse;
      }
    }

    &--reverse &-str {
      margin-right: 0;
      margin-left: 30px;
      @include mq(max, sm) {
        margin-left: 0;
      }
    }

    &-str {
      width: 60%;
      margin-right: 30px;
      @include mq(max, sm) {
        width: 100%;
        margin-right: 0;
        margin-top: 10px;
      }
    }

    &-img {
      flex: 1;
      @include mq(max, sm) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    &-txt {
      @include margin-responsive(15px);
    }

    &-link {
      display: block;
      @include margin-responsive(25px);

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }

      &::before {
        content: "＞";
        margin-right: 5px;
      }
    }
  }

  &__quote {
    padding-top: 10px;
    @include padding-bottom-responsive(40px);
    @include padding-left-responsive(40px);
    @include padding-right-responsive(40px);
    background-color: rgba($color: $main, $alpha: 0.1);
    width: 100%;

    &-icon {
      width: 38px;
      height: auto;
      display: block;
      @include margin-left-responsive(-20px);
    }

    * {
      font-size: fz(14);
      @include margin-responsive(10px);
    }
  }

  &__source {
    margin-bottom: 0;

    a {
      color: $main;
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__list {
    padding-left: 20px;
    list-style-type: disc;

    &-item {
      & + & {
        margin-top: 8px;
      }
    }

    &--num {
      list-style-type: decimal;
    }
  }

  &__table {
    width: 100%;

    th,
    td {
      padding: 10px;
      @include mq(max, sm) {
        width: 100%;
        display: block;
      }
    }

    th {
      background-color: $main;
      color: theme-color("white");
      border-top: 1px solid theme-color("white");
      border-bottom: 1px solid theme-color("white");
      font-weight: $bold;
    }

    tr {
      &:nth-child(odd) {
        td {
          background-color: theme-color("gray-100");
        }
      }
    }

    td {
      border-top: 1px solid theme-color("gray-300");
      border-bottom: 1px solid theme-color("gray-300");
      border-right: 1px solid theme-color("gray-300");
    }

    &--multi {
      width: auto;
      min-width: 100%;
      white-space: nowrap;

      th,
      td {
        display: table-cell;
      }

      th {
        + th {
          border-left: 1px solid theme-color("white");
        }
      }
    }
  }

  &__scroll {
    overflow-x: auto;
  }

  &__btn {
    width: 100%;
    max-width: 360px;
    border-radius: 50vh;
    background: $main;
    border: 1px solid $main;
    color: #fff;
    font-weight: $bold;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    &:hover {
      background: theme-color("white");
      color: $main;
      text-decoration: none;
    }

    &--left {
      margin-left: 0;
    }

    &--right {
      margin-right: 0;
    }

    & + & {
      margin-top: 15px;
    }
  }

  &__video {
    position: relative;
    padding-top: calc(9 / 16 * 100%);
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &s {
      display: flex;
      @include mq(max, sm) {
        flex-flow: column;
      }
    }

    &-wrap {
      flex: 1;
      @include mq(max, sm) {
        width: 100%;
      }

      & + & {
        margin-left: 25px;
        @include mq(max, sm) {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
  }

  &__item {
    width: 100%;

    & + & {
      @include mq(max, sm) {
        margin-top: 15px;
      }
    }

    img {
      width: 100%;
    }

    &-txt {
      @include margin-responsive(15px);
    }

    &s {
      display: flex;
      margin-left: -25px;
      @include mq(max, sm) {
        flex-flow: column;
        margin-left: 0;
      }
    }

    &s & {
      flex: 1;
      margin-left: 25px;
      @include mq(max, sm) {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  &__card {
    display: flex;
    @include padding-top-responsive(45px);
    @include padding-bottom-responsive(45px);
    @include padding-left-responsive(45px);
    @include padding-right-responsive(45px);
    background-color: #f8f9fa;
    @include mq(max, sm) {
      flex-flow: column-reverse;
    }

    &-txt {
      @include margin-responsive(15px);
    }

    &-str {
      width: 55%;
      @include mq(max, sm) {
        width: 100%;
        margin-top: 10px;
      }
    }

    &-img {
      margin-left: 40px;
      flex: 1;
      @include mq(max, sm) {
        width: 100%;
        margin-left: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__cv {
    background-color: theme-color("gray-100");
    @include padding-top-responsive(25px);
    @include padding-bottom-responsive(25px);
    @include padding-left-responsive(25px);
    @include padding-right-responsive(25px);
    display: flex;
    @include mq(max, sm) {
      flex-flow: column;
    }

    &-img {
      width: 150px;
      @include mq(max, sm) {
        margin-left: auto;
        margin-right: auto;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-str {
      margin-left: 40px;
      flex: 1;
      @include mq(max, sm) {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
      }
    }

    &-head {
      font-size: fz(24);
      font-weight: $bold;
    }

    &-txt {
      @include margin-responsive(15px);
    }

    &-btn {
      @include margin-responsive(15px);
    }
  }

  &__company {
    border: 8px solid theme-color("gray-100");
    @include padding-top-responsive(20px);
    @include padding-bottom-responsive(20px);
    @include padding-left-responsive(20px);
    @include padding-right-responsive(20px);
    display: flex;
    flex-flow: column;
    align-items: center;

    &-name {
      font-size: fz(20);
      font-weight: $bold;
      margin-bottom: 0;
    }

    &-link {
      font-size: fz(14.4);
      display: block;
      margin-top: 10px;
      &:hover {
        opacity: 0.7;
      }
    }

    &-table {
      margin-top: 20px;
    }
  }

  &__list {
    list-style: disc;

    &-item {
      margin-top: 8px;
    }
  }
}
